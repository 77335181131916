<script setup lang="ts">
import { CmsBlock, CmsSlot } from "@shopware-pwa/types";

type CmsBlockMaasJob = CmsBlock & {
  slots: Array<
    CmsSlot & {
      slot: "maasJob";
    }
  >;
};

const props = defineProps<{
  content: CmsBlockMaasJob;
}>();

const { formatLink } = useInternationalization();

const { getSlotContent } = useCmsBlock(props.content);
const slot = getSlotContent("job");

const { getConfigValue } = useCmsElementConfig(slot);
const title: string = getConfigValue("title");
const subtitle: string = getConfigValue("subtitle");

function filterUniqueObjects(inputArray, key) {
    const seen = new Set();
    return inputArray.filter(obj => {
        const objKey = obj[key];
        if (!seen.has(objKey)) {
            seen.add(objKey);
            return true;
        }
        return false;
    });
}

let jobs: any = Object.values(slot?.data?.jobs) ?? [];
const locationsChecked: any = reactive({});
const locations = filterUniqueObjects(jobs.map((item: any) => item?.location).sort((a: any, b: any) =>  (a.city > b.city) ? 1 : -1), "city");

for (const loc of locations) {
  locationsChecked[loc?.city] = false;
}

let jobsFiltered = computed(() => {
  const curLocations = Object.keys(locationsChecked).filter((key: string) => locationsChecked[key]);

  let jobsProcessed = jobs;

  if (curLocations.length > 0) {
    jobsProcessed = jobs.filter((el: any) => {
      return curLocations.includes(el.location?.city);
    })
  }
  
  return jobsProcessed.sort((a: any, b: any) =>  (a.location?.city > b.location?.city) ? 1 : -1);
});
</script>

<template>
  <div class="s-gutter">
    <div class="c-width-narrow grid-gap-22 mt-8 lg:grid lg:grid-cols-[max(220px)_4fr]" v-if="title || subtitle">
      <div class="mb-4 col-start-2">
        <h1 class="text-stage-medium leading-tight text-balance text-maas-typography-headline-primary" v-if="title">{{ title }}</h1>
        <h3 class="mt-4 text-h3" v-if="subtitle">{{ subtitle }}</h3>
      </div>
    </div>
    <div class="c-width-narrow grid-gap-22 mt-8 lg:grid lg:grid-cols-[max(220px)_4fr]">
      <div class="mb-4">
        <h3 class="text-maas-typography-text-grey-medium text-small mb-6">Standort</h3>
        <ul class="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-1 gap-x-8">
          <li v-if="locations" v-for="location in locations" :key="location?.city" class="whitespace-nowrap">
            
            <SharedFormLabelCheck
                v-if="location"
              :key="location.city"
              class="checkbox-container flex flex-col space-y-4 pb-4"
              :label="location.city"
            >
              <SharedFormCheckbox
                group="location"
                :option="String(location)"
                :selected="locationsChecked[location.city]"
                @select="locationsChecked[location.city] = !locationsChecked[location.city]"
              />
            </SharedFormLabelCheck>
          </li>
        </ul>
      </div>
      <ul>
        <li v-for="job in jobsFiltered" :key="job?.id">
          <NuxtLink
            :to="formatLink('/jobs/' + job.slug)"
            class="bg-maas-background-grey-light hover:bg-maas-background-grey-medium group block flex flex-wrap lg:flex-nowrap items-center gap-4 lg:gap-8 border-b px-4 lg:px-8 py-6 transition-colors first:border-t"
          >
            <span class="text-h4 block w-full lg:w-1/2 font-normal">{{ job.title }}</span>
            <span class="text-p block lg:w-1/4">{{ job.type }}</span>
            <span class="lg:hidden">|</span>
            <span class="text-p block lg:w-1/4">{{ job?.location?.title }}</span>
            <svg
              class="hidden lg:block transition duration-500 group-hover:translate-x-1 group-hover:scale-110"
              width="15"
              height="24"
              viewBox="0 0 15 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M2 22L12.7692 12L2 2" stroke="#2B5078" stroke-width="2" stroke-linecap="square" />
            </svg>
          </NuxtLink>
        </li>
      </ul>
    </div>
  </div>
</template>